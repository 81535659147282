
import reactLogo from '../logo.svg'

export const ReactLogo = () => {
  return (
    <img
      src={reactLogo}
      alt='React Logo'
      style={{
        position: 'fixed',
        bottom: '0px',
        right: '20px',
        width: '30px'
      }}
    />
  )
}
